/*------------------------------------
	Hamburger
------------------------------------*/
.hamburger-icon {
	display: block;
	width: $hamburger-width;
	height: $hamburger-height;
	position: relative;
	transform: $hamburger-rotate;
	transition: $hamburger-transition ease-in-out;
	cursor: pointer;
	span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		opacity: 1;
		left: 0;
		transform: $hamburger-rotate;
		transition: $hamburger-transition2 ease-in-out;
		&:nth-child(1) {
			top: $hamburger-child-1-top;
		}
		&:nth-child(2) {
			top: $hamburger-child-2-top;
		}
		&:nth-child(3) {
			top: $hamburger-child-2-top;
		}
		&:nth-child(4) {
			top: $hamburger-child-4-top;
		}
	}
	&.open span {
		&:nth-child(1) {
			top: $hamburger-child-1-top-open;
			width: 0;
			left: 50%;
		}
		&:nth-child(2) {
			transform: $hamburger-child-2-rotate;
		}
		&:nth-child(3) {
			transform: $hamburger-child-3-rotate;
		}
		&:nth-child(4) {
			top: $hamburger-child-4-top-open;
			width: 0;
			left: 50%;
		}
	}
}
.hamburger-dark{
	span {
		background: $secondary;
	}
}
.navbar-dark {
	.navbar-toggler {
		border: none;
	}
	.hamburger-icon {
		span {
			background: $hamburger-icon-background;
		}
	}
}
.navbar-light {
	.hamburger-icon {
		span {
			background: $gray-900;
		}
	}
}