.dark-skin {
    --bs-body-color: var(--#{$prefix}gray-300);
    --bs-body-bg:  var(--#{$prefix}gray-900);
    --bs-border-color: var(--#{$prefix}gray-700);
    p {
        color: var(--#{$prefix}gray-300);
    }
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        &>a {
            color: var(--#{$prefix}gray-100);
        }
    }
    .form-control, .form-check-input, input:not([type="submit"]), select, textarea {
        color: var(--#{$prefix}gray-300);
        background-color: var(--#{$prefix}black);
        border: $input-border-width solid var(--#{$prefix}border-color);
    }
    // auto dark skin
    .bg-light-dark {
        background-color: var(--#{$prefix}gray-900) !important;
    }
    .bg-light-black {
        background-color: var(--#{$prefix}black) !important;
    }
    .text-dark-light {
        color: var(--#{$prefix}gray-300) !important;
    }
    .bg-themes {
        background: var(--#{$prefix}black) !important;
    }
    .bg-white {
        .mobile-copyright p {
            color: var(--#{$prefix}gray-700);
        }
    }
    &.boxed {
        .wrapper {
            box-shadow: 0 0 3px var(--#{$prefix}gray-900);
            background-color: var(--#{$prefix}gray-900);
        }
    }
    &.framed {
        main, .second-header , .bg-nav-framed{
            background-color: rgb(0, 0, 0, .6);
        }
    }
    .bg-image {
        background-color:  var(--#{$prefix}body-bg);
    }
    .nav-tabs {
        --bs-nav-tabs-border-color: var(--#{$prefix}border-color);
    }
    .card {
        --bs-card-bg: var(--#{$prefix}body-bg);
        --bs-border-color-translucent: var(--#{$prefix}border-color);
    }
    // navbar light on dark skin
    .navbar-light {
        --bs-border-color: var(--#{$prefix}gray-300);
        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
            color: var(--#{$prefix}gray-900);
            &>a {
                color: var(--#{$prefix}gray-900);
            }
        }
        .hover-a {
            &:hover {
                a {
                    color: var(--#{$prefix}link-color);
                }
            }
        }
        .form-control {
            --bs-body-color: var(--bs-gray-700);
            color: var(--bs-gray-700);
            background-color: transparent;
            border: none;
        }
        .icones {
            color: var(--bs-gray-700);
        }
        .icon-comments {
            &:after {
                background-image: escape-svg($icon-comments);
            }
        }
    }
    .navbar-dark {
        .search-menu input[type="text"] {
            background-color: transparent;
            border: none;
            &:focus {
                background-color: var(--#{$prefix}black);
                border: var(--#{$prefix}border-color) 1px solid;
            }
        }
    }
    .sidenav-menu .navbar-dark .dropdown-menu {
        --bs-dropdown-padding-y: 0;
    }
    .footer-light {
        --bs-body-color: var(--#{$prefix}gray-700);
        p {
            color: var(--#{$prefix}gray-700);
        }
        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
            color: var(--#{$prefix}gray-900);
            &>a {
                color: var(--#{$prefix}gray-900);
            }
        }
        .hover-a {
            &:hover {
                a {
                    color: var(--#{$prefix}link-color);
                }
            }
        }
    }
    .breaking-box {
        .flickity-button {
            color:  var(--#{$prefix}body-color);
        }
    }
    .pagination {
        --bs-pagination-color: var(--bs-link-color);
        --bs-pagination-bg: var(--#{$prefix}gray-900);
        --bs-pagination-border-color: var(--#{$prefix}border-color);
        --bs-pagination-hover-color: var(--bs-link-hover-color);
        --bs-pagination-hover-bg: var(--#{$prefix}gray-800);
        --bs-pagination-hover-border-color: var(--#{$prefix}gray-800);
        --bs-pagination-focus-color: var(--bs-link-hover-color);
        --bs-pagination-focus-bg: var(--#{$prefix}gray-800);
        --bs-pagination-active-color: var(--#{$prefix}gray-900);
        --bs-pagination-disabled-bg: var(--#{$prefix}gray-900);
        --bs-pagination-disabled-border-color: var(--#{$prefix}gray-800);
    }
    .back-top {
        background: var(--#{$prefix}dark);
        color: var(--#{$prefix}light) !important;
    }
    //post
    .post-content {
        --bs-post-color: var(--#{$prefix}gray-400);
        pre {
            background-color: var(--#{$prefix}black);
        }
    }
    .u-blockquote, .wp-block-quote{
        background:  var(--#{$prefix}black);
    }
    .icon-comments {
        &:after {
            background-image: escape-svg($icon-comments-dark);
            opacity: .7;
        }
    }
    .before-caret-dark {
        a {
            &:before {
                background-image: escape-svg($chevron-down-dark);
            }
        }
    }
    .card-list {
        li {
            a::before {
                background-image: escape-svg($chevron-down-dark);
            }
        }
    }
    .stripe-line {
        background: $url_01;
    }
    .before-arrow-dark {
        li{
            &:before{
                background-image: escape-svg($chevron-down-dark);
            }
        }
    }
}
.text-dark-light {
    color: var(--#{$prefix}gray-700);
}
.sidenav-menu {
    .navbar-dark .dropdown-menu {
        --bs-dropdown-bg: var(--#{$prefix}dark);
    }
    .navbar-light .dropdown-menu {
        --bs-dropdown-bg: var(--#{$prefix}white);
    }
}
.navbar {
    .dropdown-menu {
        &[data-bs-popper] {
            left: inherit;
        }
    }
}
.navbar-dark {
    --bs-border-color: var(--#{$prefix}gray-700);
    .side-link li a {
        color: var(--#{$prefix}gray-300);
        &:hover {
            color: var(--#{$prefix}link-color);
        }
    }
    .icones {
        color:  var(--#{$prefix}gray-200);
    }
}
.footer-dark {
    .before-caret-dark {
        a {
            &:before {
                background-image: escape-svg($chevron-down-dark);
            }
        }
    }
}