/*----------------------------------------------------------------------
    * BootNews - Magazine and Blog bootstrap template
    * Copyright Bootstrap.News
    * Licensed under (https://bootstrap.news/license/)
------------------------------------------------------------------------*/
// Required
@import "../vendor/bootstrap/scss/functions";

// Any default variable overrides here
@import "custom/custom-variables";
@import "variables";

// Required
@import "../vendor/bootstrap/scss/variables";

// Required
@import "../vendor/bootstrap/scss/maps";
@import "../vendor/bootstrap/scss/mixins";
@import "../vendor/bootstrap/scss/utilities";

// Optional Bootstrap components here
@import "../vendor/bootstrap/scss/root";
@import "../vendor/bootstrap/scss/reboot";
@import "../vendor/bootstrap/scss/type";
@import "../vendor/bootstrap/scss/images";
@import "../vendor/bootstrap/scss/containers";
@import "../vendor/bootstrap/scss/grid";
@import "../vendor/bootstrap/scss/tables";
@import "../vendor/bootstrap/scss/forms";
@import "../vendor/bootstrap/scss/buttons";
@import "../vendor/bootstrap/scss/transitions";
@import "../vendor/bootstrap/scss/dropdown";
@import "../vendor/bootstrap/scss/button-group";
@import "../vendor/bootstrap/scss/nav";
@import "../vendor/bootstrap/scss/navbar";
@import "../vendor/bootstrap/scss/card";
@import "../vendor/bootstrap/scss/accordion";
@import "../vendor/bootstrap/scss/breadcrumb";
@import "../vendor/bootstrap/scss/pagination";
@import "../vendor/bootstrap/scss/badge";
@import "../vendor/bootstrap/scss/alert";
@import "../vendor/bootstrap/scss/progress";
@import "../vendor/bootstrap/scss/list-group";
@import "../vendor/bootstrap/scss/close";
@import "../vendor/bootstrap/scss/toasts";
@import "../vendor/bootstrap/scss/modal";
@import "../vendor/bootstrap/scss/tooltip";
@import "../vendor/bootstrap/scss/popover";
@import "../vendor/bootstrap/scss/carousel";
@import "../vendor/bootstrap/scss/spinners";
@import "../vendor/bootstrap/scss/offcanvas";
@import "../vendor/bootstrap/scss/placeholders";

// Helpers
@import "../vendor/bootstrap/scss/helpers";

// Utilities API
@import "../vendor/bootstrap/scss/utilities/api";


// Bootnews core
@import 'theme/bootnews';

// Custom scss
@import 'custom/custom';