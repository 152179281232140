/*------------------------------------
	Background Shadow
------------------------------------*/
.bg-shadow {
	background: linear-gradient(to bottom, rgba(179, 171, 171, 0) 0%, rgba(48, 48, 48, 0.71) 49%, rgba(19,19,19,0.8) 100%);
}
.bg-lg-shadow {
	background: linear-gradient(to bottom, rgba(16, 11, 11, 0) 0%, rgba(0, 0, 0, 0.67) 49%, rgba(19, 19, 19, 0.88) 100%);
}
.bg-shadow-two {
	background: rgba(53, 53, 53, 0.75);
}
.bg-full-shadow {
    position: absolute;
    background: var(--#{$prefix}black);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .6;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
	z-index: 2;
}