/*------------------------------------
	Border width
------------------------------------*/
.border-none {
	border-width: 0 !important;
}
// border smooth dark
.border-smooth {
	border-color: $border-smooth !important;
}
// border smooth light
.border-smooth-light {
	border-color: $border-smooth-light !important;
}
@include media-breakpoint-up(lg) {
	.border-lg-0{
		border-width: 0 !important;
	}
	.border-lg-1{
		border-width: 1px !important;
	}
}