/*------------------------------------
	Ratio Thumbnail images
	Tutorial calculate the ratio space example size 400x250px is 250/400*100 = 62.5%
------------------------------------*/
.ratio_category, .ratio_demo1, .ratio_demo2, .ratio_single, .ratio_203-114, .ratio_110-77, .ratio_115-80,  .ratio_165-92, .ratio_180-123, .ratio_230-129, .ratio_251-141, .ratio_327-278, .ratio_376-376, .ratio_372-208, .ratio_400-250, .ratio_800-450, .ratio_763-422, .ratio_540-470, .ratio_540-460, .ratio_540-454, .ratio_540-304, .ratio_466-261, .ratio_360-202, .ratio_220-150, .ratio_230-230, .ratio_337-337, .ratio_99-68, .ratio_160-112, .ratio_640-640, .ratio_1140-535, .ratio_900-500, .ratio_950-500, .ratio_1200-900 {
	height: 0;
}
.ratio_category, .ratio_demo1 {
    padding-bottom: 25%;
}
.ratio_demo2 {
    padding-bottom: 55%;
}
.ratio_1108-520 {
	padding-bottom: 46.9314079%;
}
.ratio_1140-535 {
	padding-bottom: 46.9314079%; 
}
.ratio_900-500, .ratio_763-422 {
	padding-bottom: 55.3555556%; 
}
.ratio_466-261, .ratio_165-92, .ratio_230-129, .ratio_360-202, .ratio_203-114,.ratio_251-141, .ratio_372-208 {
	padding-bottom: 56.0085837%; 
}
.ratio_800-450 {
	padding-bottom: 56.25%; 
}
.ratio_540-304 {
	padding-bottom: 56.2962963%; 
}
.ratio_400-250 {
	padding-bottom: 62.5%; 
}
.ratio_single {
	padding-bottom: 66.6666667%; 
}
.ratio_220-150, .ratio_99-68, .ratio_180-123 {
	padding-bottom: 68.1818182%; 
}
.ratio_160-112, .ratio_115-80, .ratio_110-77{
	padding-bottom: 69.5%; 
}
.ratio_1200-900 {
	padding-bottom: 75%;
}
.ratio_540-454 {
	padding-bottom: 84%;
}
.ratio_327-278 {
    padding-bottom: 85.012905%;
}
.ratio_540-460 {
	padding-bottom: 85.1851852%;
}
.ratio_540-470 {
	padding-bottom: 87.037037%;
}
.ratio_640-640, .ratio_230-230, .ratio_337-337, .ratio_376-376 {
	padding-bottom: 99.999%; 
}