/*------------------------------------
	Mega menu
------------------------------------*/
.navbar {
	.dropdown-menu {
		&.mega {
			left: auto;
			right: 0;
			.block-area {
				margin: 1.5rem .5rem;
			}
		}
		&.mega.show {
			display: block;
		}
	}
}
.mega {
	&-dropdown {
		position: static;
	}
	.title-nav {
		padding-left: $mega-item-padding-left;
		padding-right: $mega-item-padding-right;
	}
}
.navbar-expand-lg {
	.navbar-nav {
		.mega-link > li > a.dropdown-item {
			padding-left: $mega-item-padding-left;
			padding-right: $mega-item-padding-right;
		}
	}
}
.mega-link {
	.dropdown-item {
		&.active {
			background-color: var(--#{$prefix}gray-100);
		}
		&:active {
			background-color: var(--#{$prefix}gray-100);
		}
	}
}
.before-caret a:before, .before-caret-dark a:before {
	content: "";
	transform: $rotate-n90;
	margin-bottom: -2px;;
	background-repeat: no-repeat;
	background-size: 1rem;
	width: 1rem;
	height: 1rem;
	display: inline-block;
	margin-right: $font-a-margin-right;
	font-size: $font-a-size;
}
.before-caret {
	a {
		&:before {
			background-image: escape-svg($chevron-down-dark);
		}
	}
	&-dark {
		a {
			&:before {
				background-image: escape-svg($chevron-down-light);
			}
		}
	}
}
// mega menu sub link
.mega {
	ul {
		&.menu {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			margin-right: -15px;
			margin-left: -15px;
			padding-left: 0;
			list-style: none;
			&> li{
				border-right: 1px solid var(--#{$prefix}border-color);
                &:last-child {
					border-right: none;
				}
				&> a {
					color: var(--#{$prefix}gray-900);
					line-height: 2.5rem;
					font-weight: 500;
					font-size: 1rem;
					padding-left: 1.5rem;
					padding-right: 1.5rem;
					margin-bottom: 1rem;
					background: transparent;
					&::after {
						content: none !important;
					}
				}
				&:hover {
					&> a {
						color: var(--#{$prefix}gray-900);
						background-color: transparent;
					}
				}
			}
			ul {
				padding-left: 0;
			   list-style: none;
			   li {
				   a {
					display: block;
					width: 100%;
					padding: 0.5rem 1.5rem;
					clear: both;
					font-weight: 400;
					color: var(--#{$prefix}gray-900);
					text-align: inherit;
					white-space: nowrap;
					background-color:  transparent;
					border: 0;
					&::after {
							content: none !important;
					    }
				    }
			    }
			}
		}
	}
}