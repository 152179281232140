// rotate and rotate negative
.rotate {
  &-45 {
    transform: rotate(45deg);
  }
  &-n45 {
    transform: rotate(-45deg);
  }
  &-90 {
    transform: rotate(90deg);
  }
  &-n90 {
    transform: rotate(-90deg);
  }
  &-180 {
    transform: rotate(180deg);
  }
  &-n180 {
    transform: rotate(-180deg);
  }
  &-270 {
    transform: rotate(270deg);
  }
  &-n270 {
    transform: rotate(-270deg);
  }
}